import { Component,OnInit } from '@angular/core';
import { ItemsService } from './services/items.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  title = 'demoup';
  items: any;
  newItem: any = {
    email: null,
    dni: null,
    nombre: null,
    apellido: null,
    telefono: null,
    avatar: null
  };

  avatar: any;

  constructor(private itemService: ItemsService) { }
  ngOnInit() {
    this.items = this.itemService.getAllItemsWImage();
  }

  onSubmitItem(){
    console.log(this.newItem)
    this.itemService.addItem(this.newItem).subscribe(data=>{
      window.location.reload();
    })
  }

  deleteItem(param){
    this.itemService.deleteItem(param).subscribe(data=>{
      window.location.reload();
    })
  }

  private imageSrc: string = '';

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.imageSrc = reader.result;
    this.newItem.avatar = this.imageSrc.substr(this.imageSrc.indexOf(',',0)+1,this.imageSrc.length);
  }

}
