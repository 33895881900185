import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {map } from 'rxjs/operators'
import { environment } from '../../environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })}
@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  items = [];
  constructor(private httpClient: HttpClient) { }

  getAllItems(){
    return this.httpClient.get(environment.api_url + '/people/list').pipe(map((res:any) =>{ 
      var data = res.result.data;
      return data;
    }));
  }

  getAllItemsWImage(){
    
    return this.httpClient.get(environment.api_url + '/people/list').pipe(map((res:any) =>{ 
      res.result.data.forEach(element => {
        this.getSignedUrl(element.avatar.key).subscribe(res_url=>{
          let objeto =element;
          objeto["url"]= res_url;
          this.items.push(objeto);
          console.log(objeto)
        })
      });
      return this.items;
    }));
  }

  getSignedUrl(param){
    return  this.httpClient.get(environment.api_url + 'image?key='+param).pipe(map((res:any) =>{ 
      return res.result.data
    }));
  }
  addItem(param){
    return this.httpClient.post(environment.api_url + '/image', param, httpOptions).pipe(map(data => {return data}))
  }
  deleteItem(param){
    return this.httpClient.delete(environment.api_url + '/people?dni='+param).pipe(map(data => {return data}))
  }



}
